import * as React from "react";

import SEO from "../components/SEO";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 1200px) auto;
`;

export const TitleRow = styled.div`
  background-color: #282625;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Col = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 4%;
  padding-bottom: 4%;
  color: var(--white);

  @media (max-width: 980px) {
    width: initial;
    padding-right: 10%;
    padding-left: 10%;

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const TitleCol = styled.div`
  width: 50%;
  text-align: center;
  padding: 3em;
  color: var(--white);

  @media (max-width: 980px) {
    width: initial;
    padding-right: 10%;
    padding-left: 10%;

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Divider = styled.div`
  & {
    border: 1px solid var(--white);
    display: inline-block;
    width: 12%;
    border-width: 2px;
  }
`;

export const ImageTextRow = styled.section`
  display: grid;
  grid-template-columns: 600px auto;
  color: #fff;

  @media (max-width: 980px) {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const ImageContainer = styled.div``;

export const TwoImageRow = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  @media (max-width: 980px) {
    text-align: center;
    grid-template-columns: 1fr;
  }
`;

export const TextColumn = styled.div`
  background-color: #320b1a;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 36px;
    line-height: 1.2em;
    margin-bottom: 0.25em;
  }

  p {
    /* color: white; */
    font-size: 18px;
    line-height: 1.8em;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  @media (max-width: 980px) {
    padding-top: 10%;
    padding-bottom: 10%;
    order: 2;
  }
`;

export const TwoColRow = styled.section`
  display: flex;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export default function Projects() {
  return (
    <>
      <SEO title="Projects" />
      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <Col>
            <h2>Bespoke projects</h2>
            <Divider />
            <p>
              Homes are different, so as light and surroundings in your place. I
              take extra care when suggesting colours, finishes, materials and
              suggest some ideas. Throughout more than 10 years of experience
              I've developed a good eye for the details and always listening to
              what client wants.
            </p>
          </Col>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#320b1a" }}></aside>
        <ImageTextRow>
          <ImageContainer>
            <StaticImage
              style={{ border: "3rem solid #320b1a" }}
              layout="fullWidth"
              loading="eager"
              formats={["auto", "webp", "avif"]}
              alt="around the house decoration"
              src="../images/projects-page/around-the-house/around_the_house_1_banner.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <TextColumn>
            <h2>Tailored decor</h2>
            <Divider style={{ margin: "0 auto" }} />
            <p>
              Either you need advice or work on your room painting, adding the
              decor with the finish of your chose and the one that matches
              sunlight and the walls.
            </p>
            <p>
              I offer friendly service and a fair price on materials and working
              hours.
            </p>
            <p>I also do paintings that matches your interior.</p>
          </TextColumn>
        </ImageTextRow>
        <aside style={{ background: "#320b1a" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <TitleCol>
            <h2>Bathroom</h2>
            <Divider />
          </TitleCol>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom sink wall decoration with a mirror"
            src="../images/projects-page/bathroom/bathroom_1.jpg"
            placeholder="blurred"
          />

          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom shower wall decoration grey"
            src="../images/projects-page/bathroom/bathroom_2.jpg"
            placeholder="blurred"
          />
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom toilet wall with light"
            src="../images/projects-page/bathroom/bathroom_3.jpg"
            placeholder="blurred"
          />

          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom tolet with bath and grey decor wall"
            src="../images/projects-page/bathroom/bathroom_4.jpg"
            placeholder="blurred"
          />
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom toilet and sink and grey decor wall"
            src="../images/projects-page/bathroom/bathroom_5.jpg"
            placeholder="blurred"
          />

          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom sink against black decoration wall"
            src="../images/projects-page/bathroom/bathroom_6.jpg"
            placeholder="blurred"
          />
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom toilet and sink preparation"
            src="../images/projects-page/bathroom/bathroom_7.jpg"
            placeholder="blurred"
          />

          <StaticImage
            formats={["auto", "webp", "avif"]}
            alt="bathroom sink after work"
            src="../images/projects-page/bathroom/bathroom_8.jpg"
            placeholder="blurred"
          />
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <TitleCol>
            <h2>Kitchen</h2>
            <Divider />
          </TitleCol>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="kitchen marble finish"
              src="../images/projects-page/kitchen/kitchen_1x800.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="kitchen marble finish from angle creates different colour"
              src="../images/projects-page/kitchen/kitchen_2x800.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <TitleCol>
            <h2>Staircase</h2>
            <Divider />
          </TitleCol>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase dark gold finish from upstairs different angle"
              src="../images/projects-page/staircase/staircase_1.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase dark gold finish from upstairs preparation"
              src="../images/projects-page/staircase/staircase_2.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase dark gold finish from upstairs"
              src="../images/projects-page/staircase/staircase_3.jpg"
              placeholder="blurred"
              style={{ height: "100%" }}
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase dark gold finish from above"
              src="../images/projects-page/staircase/staircase_4.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase white finish from upstairs"
              src="../images/projects-page/staircase/staircase_5.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase white finish from above"
              src="../images/projects-page/staircase/staircase_6.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase with light bright colours"
              src="../images/projects-page/staircase/staircase_7.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="staircase brown finish"
              src="../images/projects-page/staircase/staircase_8.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <TitleCol>
            <h2>Around the house</h2>
            <Divider />
          </TitleCol>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="around the house hallway"
              src="../images/projects-page/around-the-house/around_the_house_1.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="around the house hallway with mirror"
              src="../images/projects-page/around-the-house/around_the_house_2.jpg"
              placeholder="blurred"
            />
            <div
              style={{
                background: "#282625",
                height: "100%",
                textAlign: "center",
                padding: "4em",
              }}
            >
              <section>
                <h3>Bright walls</h3>
                <p>Decor with nice finish to bright up the coridor</p>
              </section>
            </div>
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="living room wall prepared for applying decor finish"
              src="../images/projects-page/around-the-house/around_the_house_3.jpg"
              placeholder="blurred"
            />
            <div
              style={{
                background: "#282625",
                height: "100%",
                textAlign: "center",
                padding: "4em",
              }}
            >
              <section>
                <h3>TV, sound system and fireplace decor</h3>
                <p>
                  Client was very reluctant to modify wall so the TV is mounted
                  to the wall with the sound system. Also, the fireplace gives
                  the room the atmoshpere you would like to have
                </p>
              </section>
            </div>
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="Fireplace mounted to new decor finish"
              src="../images/projects-page/around-the-house/around_the_house_4.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="around the house hallway angle from below"
              src="../images/projects-page/around-the-house/around_the_house_5.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="around the house hallway wall next to door"
              src="../images/projects-page/around-the-house/around_the_house_6.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="bedroom wall new finish"
              src="../images/projects-page/around-the-house/around_the_house_7.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="bedroom wall with beautiful finish"
              src="../images/projects-page/around-the-house/around_the_house_8.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TitleRow>
          <TitleCol>
            <h2>Furniture Frames</h2>
            <Divider />
          </TitleCol>
        </TitleRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      <Container>
        <aside style={{ background: "#282625" }}></aside>
        <TwoImageRow>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="mirror frame before applying new finish"
              src="../images/projects-page/furniture/frame_before.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
          <ImageContainer>
            <StaticImage
              formats={["auto", "webp", "avif"]}
              alt="mirror frame after finish"
              src="../images/projects-page/furniture/frame_after.jpg"
              placeholder="blurred"
            />
          </ImageContainer>
        </TwoImageRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>
    </>
  );
}
